import React, {
  useState,
  useRef,
  useEffect,
} from "react";

import {
	ad_servers, dimension_types_expandable_yes,
	dimension_types_mraid_no, dimension_types_mraid_yes,
	expansion_directions,
	expansion_triggers, file_types,
	mraid_types,
	tag_types
} from "../../fixtures";

import {
	Button,
	Divider,
	Dropdown,
	Form,
	Input,
	Radio,
	Ref,
  TextArea,
  Select,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";

import {
  CreativeDisplay1PModel,
  CreativeDisplay3PModel,
} from "../../../../models/creative_display";

import {
  formatDateToISO,
  humanFileSize,
} from "../../../../libs/common_utils";

import {
  handleNumber,
  useForm,
} from "../../../../libs/component_utils";

import "react-datepicker/dist/react-datepicker.css";
import AgenciesService from "../../../../services/agencies";
import { Config } from "../../../../config/api";
import { CreativePreviewer } from "../../creative_previewer/creativePreviewer";
import CreativesService from "../../../../services/creatives";
import StartEndDateFormField from "../../../common/StartEndDateFormField";
import { FileInputComponent } from "../../../common/fileInput";
import "../index.css";
import PropTypes from "prop-types";
import T1Service from "../../../../services/t1";
import { useIntl } from "react-intl";
import { useFlags } from "launchdarkly-react-client-sdk";
import OnSiteDateTimeControl from "../common/OnSiteDateTimeControl";

/**
 * Creative create page component
 * @param history
 * @return {*}
 * @constructor
 */
const CreativeDisplayForm = (
	{
		initialData,
		onSubmit,
		onCancel,
		loading,
		formType = "create",
		submitText = "Create Creative", assetRestriction,
		onsite_enabled
}) => {
	const services = React.useRef(
		new Map([
			["creatives", new CreativesService()],
			["t1", new T1Service()],
			["agencies", new AgenciesService()],
		])
	);

	const agency = services.current.get("agencies").getSelectedAgency() || 0;
	const isEditMode = (formType.toString() === "edit");
	const additionalFields = React.useRef({});
	const childRef = useRef();
	const intl = useIntl();

	// loaded page data
	const [pageData, setPageData] = useState(() => {
		return {
			"advertisersLoading": true,
			"advertisers": [],
		};
	});

	/**
	 * updated fields from additional components
	 * @param {object} fields
	 */
	const updateAdditionalFields = fields => {
		additionalFields.current = {
			...additionalFields.current,
			...fields
		};
	};

	/**
	 * handle end date change
	 * @param {Date} date
	 */
	const handleEndDateChange = date => {
		onChange(null, {"name": "end_date", "value": date});
	};

	/**
	 * handle creative type change
	 * @param {Event} e
	 * @param {object} input
	 */
	const handleCreativeTypeChange = (e, input) => {
		updateValues({
			...values,
			"creative_type": input.value,
			"third_party": input.value === "on_site" ? 0 : values.third_party
		})
	};

	/**
	 * initially loading advertisers list
	 */
	React.useEffect(() => {
		const service = services.current.get("t1");
		(async () => {
			try {
				const r = await service.advertisers(agency);

				/** @namespace r.data **/
				setPageData({
					...pageData,
					"advertisersLoading": false,
					"advertisers": pageData.advertisers.concat(
						r.data.map(({ id, title }) => ({
							"key": id,
							"text": title,
							"value": id,
						}))
					),
				});
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	/**
	 * form submit handler
	 */
	const addCreativeForm = async () => {
		const params = Object.assign(values, additionalFields.current);
		const model = (params.third_party)? CreativeDisplay3PModel : CreativeDisplay1PModel;
		const creative = model.fromJson(params);

		let creative_json = creative.toJson();

		if(onsite_enabled && !isEditMode && values.creative_type === "on_site") {
			creative_json["end_date"] = formatDateToISO(values.end_date, true);
		}
		await onSubmit(creative_json, childRef.current.getFile(), values.creative_type);
	};

	/**
	 * check that direction is non restricted
	 * @param {string} direction
	 * @return {boolean}
	 */
	const isNonRestricted = direction => {
		return direction.toString().toLowerCase() !== "nonrestricted";
	}

	/**
	 * check that we in edit mode and trying to edit onsite creative
	 * @param {object} fields
	 * @returns {boolean}
	 */
	const isEditOnSite = fields => {
		return isEditMode && fields.creative_type === "on_site";
	}

  const {
    whitelabelCreativePreview,
  } = useFlags();

	const {
		values,
		errors,
		onChange,
		updateValues,
		onSubmit: handleSubmit,
	} = useForm(addCreativeForm, () => {
		let d = initialData.expansion_direction;
		if(d && isNonRestricted(initialData.expansion_direction)) {
			d = initialData.expansion_direction.split(",");
		}

		return {
			...initialData,
			"expansion_direction": d
		}
	}, () => {
			let errors = {};

		if(values.title && !!values.title.match(/[^a-z0-9\-_ ]/i)) {
			errors.title = intl.formatMessage({
				id: "ERROR_CREATIVE_TITLE_PATTERN",
				defaultMessage: "Creative title may only contain letters, numbers, spaces, underscores, and dashes.",
			});
		}

			if (values.advertiser_id === null) {
				errors.advertiser_id = intl.formatMessage({
					id: "ERROR_EMPTY_ADVERTISER_ID",
					defaultMessage: "Please select an advertiser.",
				});
			}

			errors = Object.assign(errors, childRef.current.validate());

      if ((initialData.start_date && values.start_date === null) || (initialData.end_date && values.end_date === null) ){
        errors.hasInitialDate = intl.formatMessage({
          id: "CANNOT_EMPTY_CREATIVE_DATE",
          defaultMessage: "Creatives with an initial start or end date must have a start or end date specified and cannot be left empty."
        });
      };

			return errors;
	});

	return (
		<>
			{isEditOnSite(values) && <Segment secondary><Icon name="attention" />{intl.formatMessage({
					id: "NOTE_ONSITE_CREATIVE_STATUS_CHANGE",
					defaultMessage: "This is an approved onsite creative. Only a status change is permitted.",
				})}</Segment>}
			<Form
				onSubmit={handleSubmit}
				size="small"
				loading={loading}
				noValidate
				error={!!Object.keys(errors).length}
				autoComplete="off"
			>
				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Radio
						name="status"
						label={intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
						})}
						value={1}
						checked={Boolean(values.status)}
						onChange={onChange}
					/>
					<Radio
						style={{ "marginLeft": "15px" }}
						name="status"
						label={intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
						})}
						value={0}
						checked={!Boolean(values.status)}
						onChange={onChange}
					/>
				</Form.Field>
				<Form.Field inline error={errors.hasOwnProperty("title")} required>
					<label>
						{intl.formatMessage({
							id: "LABEL_CREATIVE_NAME",
							defaultMessage: "Creative Name",
						})}
					</label>
					<Input
						name="title"
						required
						minLength={1}
						maxLength={100}
						defaultValue={values.title}
						disabled={isEditOnSite(values)}
						onBlur={onChange}
					/>
					<div className="custom-error">{errors["title"]}</div>
				</Form.Field>
				<Form.Field
					inline
					error={errors.hasOwnProperty("advertiser_id")}
					required
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_ADVERTISER",
							defaultMessage: "Advertiser",
						})}
					</label>
					<Dropdown
						required
						search
						selection
						disabled={isEditMode}
						options={pageData.advertisers}
						loading={pageData.advertisersLoading}
						placeholder={intl.formatMessage({
							id: "HINT_ADVERTISER",
							defaultMessage: "Select advertiser",
						})}
						name="advertiser_id"
						value={values.advertiser_id}
						onChange={onChange}
					/>
					<div className="custom-error">{errors["advertiser_id"]}</div>
				</Form.Field>

				{onsite_enabled &&
          <>
            <Form.Field inline>
              <label>
                {intl.formatMessage({
                  id: "LABEL_CREATIVE_TYPE",
                  defaultMessage: "Creative Type",
                })}&nbsp;<Popup
                inverted
                content={intl.formatMessage({
                  id: "HINT_ON_SITE_CREATIVE",
                  defaultMessage: "On-site creatives should follow the guidelines set by walmart and requires additional approval, before assigning it on strategies.",
                })}
                size="mini"
                trigger={
                  <Icon
                    name="help circle"
                    style={{ "position": "relative" }}
                    className="cursor-help"
                  />
                }
              />
              </label>
              <Radio
                name="creative_type"
                label={intl.formatMessage({
                  id: "LABEL_CREATIVE_TYPE_ON_SITE",
                  defaultMessage: "On-site",
                })}
                value="on_site"
                checked={values.creative_type === "on_site"}
                onChange={handleCreativeTypeChange}
                disabled={isEditMode}
              />
              <Radio
                style={{"marginLeft": "15px"}}
                name="creative_type"
                label={intl.formatMessage({
                  id: "LABEL_CREATIVE_TYPE_OFF_SITE",
                  defaultMessage: "Off-site",
                })}
                value="off_site"
                checked={values.creative_type === "off_site"}
                onChange={handleCreativeTypeChange}
                disabled={isEditMode}
              />
            </Form.Field>
            {values.creative_type === "on_site" ?
              <OnSiteDateTimeControl onChange={handleEndDateChange}
                                    end_date={values.end_date}
                                    error={errors["end_date"]}
                                    config={Config}
                                    is_edit={isEditMode} />
              :
              <StartEndDateFormField
                values={values}
                updateValues={updateValues}
                isEditMode={isEditMode}
                error={errors["hasInitialDate"]}
                shouldFilterStartPassedTime={false}
              />
            }
          </>
        }
				{!isEditMode && !assetRestriction && values.creative_type !== "on_site" &&
					<Form.Field inline>
						<label>
							{intl.formatMessage({
								id: "LABEL_ASSET",
								defaultMessage: "Asset",
							})}
						</label>
						<Radio
							name="third_party"
							label={intl.formatMessage({
								id: "ASSET_FIRST_PARTY",
								defaultMessage: "Upload a creative asset",
							})}
							value={0}
							checked={!values.third_party}
							onChange={onChange}
						/>
						<Radio
							style={{"marginLeft": "15px"}}
							name="third_party"
							label={intl.formatMessage({
								id: "ASSET_THIRD_PARTY",
								defaultMessage: "Enter a third party ad tag",
							})}
							value={1}
							checked={!!values.third_party}
							onChange={onChange}
						/>
					</Form.Field>
				}

				{!values.third_party? <CreativeAsset root_values={values}
																						 root_errors={errors}
																						 formType={formType}
																						 ref={childRef}
																						 isEditMode={isEditMode}
																						 is_on_site={isEditOnSite(values)}
																						 update={updateAdditionalFields} /> :
					<ThirdPartyAsset root_values={values}
													 root_errors={errors}
													 ref={childRef}
													 isEditMode={isEditMode}
													 is_on_site={isEditOnSite(values)}
													 update={updateAdditionalFields} />}

				<Form.Field
					inline
					error={errors.hasOwnProperty("landing_page_url")}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_LANDING_PAGE_URL",
							defaultMessage: "Landing Page URL",
						})}
					</label>
					<Input
						type="url"
						pattern="https?://.*\..*"
						title={intl.formatMessage({
							id: "ERROR_URL_PATTERN",
							defaultMessage: "must be a valid non-local HTTP URL",
						})}
						name="landing_page_url"
						placeholder="http://www.example.com/"
						defaultValue={values.landing_page_url}
						style={{"width": "300px"}}
						disabled={isEditOnSite(values)}
						onBlur={onChange}
					/>
					<div className="custom-error">{errors["landing_page_url"]}</div>
				</Form.Field>

        {
          initialData.id && whitelabelCreativePreview &&
            <Form.Field inline style={{ verticalAlign: "top"  }}>
              <label style={{verticalAlign: "top"}}>{intl.formatMessage({
                id: "LABEL_CREATIVE_PREVIEW",
                defaultMessage: "Creative Preview"
              })}</label>
              <div className="ui input">
                <CreativePreviewer
                  creativeId={initialData.id}
                  hideDataTables
                />
              </div>
            </Form.Field>
        }

				<Divider hidden />
				<Divider hidden />
				<Divider hidden />
				<Form.Field align="right" disabled={pageData.advertisersLoading}>
					<Button size="tiny" type="button" onClick={onCancel}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" color="green" type="submit">
						{submitText}
					</Button>
				</Form.Field>
			</Form>
		</>
	);
};

const CreativeAsset = React.forwardRef(({root_values, root_errors, formType, isEditMode, is_on_site, update}, ref) => {
	const fileSize = {
		"MIN": 10000,
		"MAX": 500e+3,
		"MAX_ZIP": 100e+4
	};
	const validExtensions = ["swf", "gif", "jpg", "jpeg", "tif", "tiff", "png", "zip"];

	const intl = useIntl();

	const fileRef = useRef();

	let {values, errors, onChange} = useForm(console.log, {
		"creative_file": root_values.creative_file,
		"tag": root_values.tag,
		"mraid": root_values.mraid,
		"mraid_type": root_values.mraid_type,
		"dimension": root_values.dimension,
		"dimensions": root_values.dimensions,
		"file_type": root_values.file_type,
		"clickthrough_url": root_values.clickthrough_url
	}, () => {});

	React.useImperativeHandle(ref, () => ({
		getFile() {
			return fileRef.current.querySelector("[type='file']")?.files[0];
		},
		validate () {
			let errors = {};

			if(formType === "create") {
				const fileInput = fileRef.current.querySelector("[type='file']");
				if (fileInput && fileInput.files.length === 0) {
					errors.creative_file = intl.formatMessage({
						id: "ERROR_EMPTY_CREATIVE_FILE",
						defaultMessage: "Please select a creative asset.",
					});
				}

				if (fileInput.files.length) {
					const ext = fileInput.files[0].name.split(".").pop().toLowerCase();
					if(!~validExtensions.indexOf(ext)) {
						errors.creative_file = intl.formatMessage({
							id: "ERROR_BAD_CREATIVE_FILE_TYPE",
							defaultMessage: "Creative asset should have one of the following extensions: {valid_extensions}.",
						}, {
							valid_extensions: validExtensions.join(", ")
						});
					}

					if(fileInput.files[0]?.size) {
						const file_size = fileInput.files[0].size;
						const max_size = (ext === "zip") ? fileSize.MAX_ZIP : fileSize.MAX;
						const max_size_human = ext === "zip" ? "1 MB" : "500 KB";

						if(file_size < 1000) {
							errors.creative_file = intl.formatMessage({
								id: "ERROR_CREATIVE_FILE_TOO_SMALL",
								defaultMessage: "Creative asset size cannot be less than {file_min_size}.",
							}, {
								"file_min_size": humanFileSize(1000)
							});
						}

						if (file_size > max_size) {
							errors.creative_file = intl.formatMessage(
								{
									id: "ERROR_BAD_CREATIVE_FILE_SIZE",
									defaultMessage:
										"Creative asset size cannot be more than {max_size}.",
								},
								{
									max_size: max_size_human,
								}
							);
						}
					}
				}

				// validation for additional mraid fields
				if (values.mraid && !values.mraid_type) {
					errors.mraid_type = "Please select an MRAID Type.";
				}
			}
			return errors;
		}
	}));

	// update parent component
	React.useEffect(() => {
		update(values);
	}, [values]);

	if(Object.keys(root_errors).length > 0) {
		errors = Object.assign(root_errors, errors);
	}

	return <>
		<Form.Field
			inline
			error={errors.hasOwnProperty("creative_file")}
			required
		>
			<label>
				{intl.formatMessage({
					id: "LABEL_ASSET",
					defaultMessage: "Asset",
				})}
			</label>
			<Ref innerRef={fileRef}>
				{isEditMode ? (
					<span>{values.creative_file}</span>
				) : (
					<FileInputComponent
						accept={validExtensions.map(n => `.${n}`).toString()}
						required={true}
						name="creative_file"
						placeholder={intl.formatMessage({
							id: "HINT_ASSET",
							defaultMessage: "Upload an asset from your computer",
						})}
					/>
				)}
			</Ref>
			<div className="custom-error">{errors["creative_file"]}</div>
		</Form.Field>

		<Form.Field
			inline
			error={errors.hasOwnProperty("tag")}>
			<label>
				{intl.formatMessage({
					id: "LABEL_3RD_PARTY_TAGS",
					defaultMessage: "3rd Party Tags",
				})}
			</label>
			<div style={{"display": "inline-block"}}>
				<div>
					{intl.formatMessage({
						id: "BODY_3RD_PARTY_TAGS",
						defaultMessage: "Insert 3rd party pixels or tags like impression tracking retargeting, ad verification or OBA.",
					})}
				</div>
				<TextArea
					style={{"width": "650px"}}
					placeholder={`<div style="display:none"><img alt="" src="https://tracking.com" /></div>`}
					name="tag"
					rows="5"
					defaultValue={values.tag}
					disabled={is_on_site}
					onBlur={onChange}
				/>
			</div>
			<div className="custom-error">{errors["tag"]}</div>
		</Form.Field>

		{isEditMode && <Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_DIMENSIONS",
					defaultMessage: "Dimensions",
				})}
			</label>
			<div style={{"display": "inline-block"}}>
				{values.dimensions}
			</div>
		</Form.Field>}

		{isEditMode && <Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_FILE_TYPE",
					defaultMessage: "File Type",
				})}
			</label>
			<div style={{"display": "inline-block"}}>
				{values.file_type}
			</div>
		</Form.Field>}

		{isEditMode? <Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_MRAID",
						defaultMessage: "MRAID",
					})}
				</label>
				<div style={{"display": "inline-block"}}>
					{values.mraid? "Yes" : "No"}
				</div>
			</Form.Field> :
			<MraidCreateField mraid={values.mraid}
												mraid_type={values.mraid_type}
												onChange={onChange}
												errors={errors} />}

		<Form.Field
			inline
			error={errors.hasOwnProperty("clickthrough_url")}
			required
		>
			<label>
				{intl.formatMessage({
					id: "LABEL_CLICKTHROUGH_URL",
					defaultMessage: "Clickthrough URL",
				})}
			</label>
			<Input
				type="url"
				pattern="https?://.*\..*"
				title={intl.formatMessage({
					id: "ERROR_URL_PATTERN",
					defaultMessage: "must be a valid non-local HTTP URL",
				})}
				required
				name="clickthrough_url"
				placeholder="http://www.example.com/"
				defaultValue={values.clickthrough_url}
				disabled={is_on_site}
				style={{"width": "300px"}}
				onBlur={onChange}
			/>
			<div className="custom-error">{errors["clickthrough_url"]}</div>
		</Form.Field>

	</>;
});

const MraidCreateField = ({mraid, mraid_type, onChange, errors}) => {
	const intl = useIntl();
	return <Form.Field inline
										 error={errors.hasOwnProperty("mraid_type")}>
		<label style={{"verticalAlign": "top"}}>
			{intl.formatMessage({
				id: "LABEL_MRAID",
				defaultMessage: "MRAID",
			})}
		</label>
		<div style={{"display": "inline-block"}}>
			<div>
				<Radio
					name="mraid"
					label={intl.formatMessage({
						id: "LABEL_YES",
						defaultMessage: "Yes",
					})}
					value={1}
					checked={!!mraid}
					onChange={onChange}
				/>
				<Radio
					style={{"marginLeft": "15px"}}
					name="mraid"
					label={intl.formatMessage({
						id: "LABEL_NO",
						defaultMessage: "No",
					})}
					value={0}
					checked={!mraid}
					onChange={onChange}
				/>
			</div>
			{Boolean(mraid) && <div style={{"marginTop": "15px"}}>
				<Select
					name="mraid_type"
					options={mraid_types(intl)}
					defaultValue={mraid_type}
					placeholder={intl.formatMessage({
						id: "HINT_MRAID_TYPE",
						defaultMessage: "Select one",
					})}
					onChange={onChange}
				/>
			</div>}
		</div>
		<div className="custom-error">{errors["mraid_type"]}</div>
	</Form.Field>;
};
MraidCreateField.propTypes = {
	"mraid": PropTypes.number.isRequired,
	"mraid_type": PropTypes.string.isRequired,
	"onChange": PropTypes.func.isRequired,
	"errors": PropTypes.object.isRequired
};

const ThirdPartyAsset = React.forwardRef(({root_values, root_errors, is_on_site, update}, ref) => {
	/**
	 * get custom option list based on values
	 * @param {object} values
	 * @return {array}
	 */
	const getDimensionOptions = values => {
		const mraid = Boolean(values.mraid),
			is_expandable = Boolean(values.is_expandable);

		if(is_expandable) {
			return dimension_types_expandable_yes;
		} else if(mraid) {
			return dimension_types_mraid_yes;
		}

		return dimension_types_mraid_no;
	}

	const intl = useIntl();

	let {values, errors, updateValues, onChange} = useForm(() => {}, () => {
		const dimensions = getDimensionOptions(root_values);
		const found = !root_values.dimensions || dimensions.find(x => x.value === root_values.dimensions);
		const dimension = (Boolean(root_values.dimensions))? root_values.dimensions.split("x") : "";

		return {
			"tpas_tag_id": root_values.tpas_tag_id || "",
			"ad_server": root_values.ad_server,
			"tag": root_values.tag,
			"tag_type": root_values.tag_type,
			"mraid": root_values.mraid,
			"is_expandable": root_values.is_expandable,
			"expansion_trigger": root_values.expansion_trigger,
			"expansion_direction": root_values.expansion_direction || expansion_directions(intl)[0].value,
			"file_type": root_values.file_type,
			"dimension": (found)? "standard" : "custom",
			"dimensions": root_values.dimensions,
			"dimension_width": dimension[0] || "",
			"dimension_height": dimension[1] || ""
	}}, () => {});
	const _isMounted = React.useRef(false);
	React.useImperativeHandle(ref, () => ({
		getFile() {
			return null;
		},
		validate () {
			let errors = {};
			if (!values.ad_server) {
				errors.ad_server = intl.formatMessage({
					id: "ERROR_NULL_AD_SERVER_NAME",
					defaultMessage: "Please select an Ad Server Name.",
				});
			}

			if (!values.tag_type) {
				errors.tag_type = intl.formatMessage({
					id: "ERROR_NULL_AD_TAG_TYPE",
					defaultMessage: "Please select an Ad Tag Type.",
				});
			}

			// validation for additional expandable fields
			if (values.is_expandable) {
				if (!values.expansion_trigger) {
					errors.expansion_trigger = intl.formatMessage({
						id: "ERROR_NULL_EXPANSION_TRIGGER",
						defaultMessage: "Please select an Expansion Trigger.",
					});
				}

				if (!values.expansion_direction.toString()) {
					errors.expansion_direction = intl.formatMessage({
						id: "ERROR_NULL_EXPANSION_DIRECTION",
						defaultMessage: "Please select an Expansion Direction.",
					});
				}
			}

			// validation for dimension
			if(values.dimension === "standard") {
				if (!values.dimensions) {
					errors.mraid_type = intl.formatMessage({
						id: "ERROR_NULL_DIMENSION",
						defaultMessage: "Please select a Dimension.",
					});
				}
			} else {
				if (!values.dimension_width || !values.dimension_height) {
					errors.mraid_type = intl.formatMessage({
						id: "ERROR_NULL_HEIGHT_ARE_REQUIRED",
						defaultMessage: "Dimension width and height are required.",
					});
				}
			}

			if (!values.file_type) {
				errors.file_type = intl.formatMessage({
					id: "ERROR_NULL_FILE_TYPE",
					defaultMessage: "Please select a File Type.",
				});
			}

			return errors;
		}
	}));

	// update parent component
	React.useEffect(() => {
		if(values.dimension === "custom" && values.dimension_width) {
			values.dimensions = `${values.dimension_width}x${values.dimension_height}`;
		}

		update(values);
	}, [values]);

	React.useLayoutEffect(() => {
		if(!_isMounted.current) {
			return;
		}

		if(values.dimension === "standard") {
			updateValues({
				...values,
				"dimensions": "",
				"dimension_width": "",
				"dimension_height": ""
			});
		} else {
			updateValues({
				...values,
				"dimensions": ""
			});
		}
	}, [values.dimension]);

	React.useLayoutEffect(() => {
		if(!_isMounted.current) {
			return;
		}

		if(values.dimension === "standard") {
			updateValues({
				...values,
				"dimensions": "",
				"dimension_width": "",
				"dimension_height": ""
			});
		}
	}, [values.mraid, values.is_expandable]);

	React.useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		}
	}, []);

	/**
	 * check that direction is non restricted
	 * @param {string} direction
	 * @return {boolean}
	 */
	const isNonRestricted = direction => {
		return direction.toString().toLowerCase() !== "nonrestricted";
	}

	/**
	 * customly change direction
	 * @param e
	 * @param {object} option
	 */
	const onChangeDirection = (e, option) => {
		let value = option.value;
		if(~option.value.indexOf("NONRESTRICTED")) {
			value = "NONRESTRICTED";
		} else if(!Array.isArray(value)) {
			value = [value];
		}

		onChange(null, {"name": "expansion_direction", "value": value});
	};

	if(Object.keys(root_errors).length > 0) {
		errors = Object.assign(root_errors, errors);
	}

	// handler for dimensions
	if(errors["dimension_width"] || errors["dimension_height"]) {
		errors["mraid_type"] = errors["dimension_width"] || errors["dimension_height"];
	}

	return <>
		<Form.Field
			inline
			error={errors.hasOwnProperty("tag")}
			required
			className="ad_tag_field"
		>
			<label>
				{intl.formatMessage({
					id: "LABEL_AD_TAG",
					defaultMessage: "Ad Tag",
				})}
			</label>
			<div>
				<div>
					<TextArea
						placeholder={intl.formatMessage({
							id: "HINT_AD_TAG",
							defaultMessage: "Place ad tag code here",
						})}
						required
						name="tag"
						rows="16"
						defaultValue={values.tag}
						disabled={is_on_site}
						onBlur={onChange}
					/>
				</div>
				<div>
				<article>
					{intl.formatMessage(
						{
							id: "BODY_AD_TAG_MACROS_1",
							defaultMessage: `
								<b>Note:</b> to ensure proper ad serving, click
								tracking and reporting you must insert click tracking
								and cache busting macros into the ad tag source.`,
						},
						{
							b: (text) => <strong>{text}</strong>,
						}
					)}
				</article>
				{/* eslint-disable @calm/react-intl/missing-formatted-message */}
				<article>
					{intl.formatMessage({
						id: "BODY_AD_TAG_MACROS_2",
						defaultMessage: `
							For click tracking, insert one of the following macros.
							Consult your ad server’s documentation for details about
							which click redirect URL encoding format is required:`,
					})}
					<ul>
						<li>[UNENCODED_CLICK_REDIRECT]</li>
						<li>[ENCODED_CLICK_REDIRECT]</li>
						<li>[DBL_ENCODED_CLICK_REDIRECT]</li>
					</ul>
				</article>
				<article>
					{intl.formatMessage({
						id: "BODY_AD_TAG_MACROS_3",
						defaultMessage: "For cache busting use:",
					})}
					<ul>
						<li>[RANDOM_NUMBER]</li>
					</ul>
				</article>
				{/* eslint-enable @calm/react-intl/missing-formatted-message */}
				</div>
			</div>

			<div className="custom-error">{errors["tag"]}</div>
		</Form.Field>
		<Form.Field inline error={errors.hasOwnProperty("ad_server")} required>
			<label>
				{intl.formatMessage({
					id: "LABEL_AD_SERVER_NAME",
					defaultMessage: "Ad Server Name",
				})}
			</label>
			<Select
				options={ad_servers(intl)}
				name="ad_server"
				size="small"
				placeholder={intl.formatMessage({
					id: "HINT_AD_SERVER",
					defaultMessage: "Select one",
				})}
				defaultValue={values.ad_server}
				disabled={is_on_site}
				onChange={onChange}
			/>
			<div className="custom-error">{errors["ad_server"]}</div>
		</Form.Field>
		<Form.Field inline error={errors.hasOwnProperty("tpas_tag_id")} required>
			<label>
				{intl.formatMessage({
					id: "LABEL_3PAS_TAG_ID",
					defaultMessage: "3PAS Tag ID",
				})}
			</label>
			<Input
				name="tpas_tag_id"
				defaultValue={values.tpas_tag_id}
				required
				disabled={is_on_site}
				onBlur={onChange}
			/>
			<div className="custom-error">{errors["tpas_tag_id"]}</div>
		</Form.Field>
		<Form.Field inline error={errors.hasOwnProperty("tag_type")} required>
			<label>
				{intl.formatMessage({
					id: "LABEL_AD_TAG_TYPE",
					defaultMessage: "Ad Tag Type",
				})}
			</label>
			<Select
				options={tag_types(intl)}
				name="tag_type"
				placeholder={intl.formatMessage({
					id: "HINT_TAG_TYPE",
					defaultMessage: "Select one",
				})}
				size="small"
				disabled={is_on_site}
				defaultValue={values.tag_type}
				onChange={onChange}
			/>
			<div className="custom-error">{errors["tag_type"]}</div>
		</Form.Field>
		<Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_MRAID",
					defaultMessage: "MRAID",
				})}
			</label>
			<Radio
				name="mraid"
				label={intl.formatMessage({
					id: "LABEL_YES",
					defaultMessage: "Yes",
				})}
				value={1}
				checked={!!values.mraid}
				onChange={onChange}
				disabled={is_on_site}
			/>
			<Radio
				style={{"marginLeft": "15px"}}
				name="mraid"
				label={intl.formatMessage({
					id: "LABEL_NO",
					defaultMessage: "No",
				})}
				value={0}
				checked={!values.mraid}
				onChange={onChange}
				disabled={is_on_site}
			/>
		</Form.Field>
		<Form.Field inline>
			<label>
				{intl.formatMessage({
					id: "LABEL_EXPANDABLE",
					defaultMessage: "Expandable",
				})}
			</label>
			<Radio
				name="is_expandable"
				label={intl.formatMessage({
					id: "LABEL_YES",
					defaultMessage: "Yes",
				})}
				value={1}
				checked={!!values.is_expandable}
				onChange={onChange}
				disabled={is_on_site}
			/>
			<Radio
				style={{"marginLeft": "15px"}}
				name="is_expandable"
				label={intl.formatMessage({
					id: "LABEL_NO",
					defaultMessage: "No",
				})}
				value={0}
				checked={!values.is_expandable}
				onChange={onChange}
				disabled={is_on_site}
			/>
		</Form.Field>
		{Boolean(values.is_expandable) && <>
			<Form.Field inline required error={errors.hasOwnProperty("expansion_trigger")}>
				<label>
					{intl.formatMessage({
						id: "LABEL_EXPANSION_TRIGGER",
						defaultMessage: "Expansion Trigger",
					})}
				</label>
				<Select
					options={expansion_triggers(intl)}
					name="expansion_trigger"
					size="small"
					placeholder={intl.formatMessage({
						id: "HINT_EXPANSION_TRIGGER",
						defaultMessage: "Select one",
					})}
					defaultValue={values.expansion_trigger}
					onChange={onChange}
					disabled={is_on_site}
				/>
				<div className="custom-error">{errors["expansion_trigger"]}</div>
			</Form.Field>
			<Form.Field inline required error={errors.hasOwnProperty("expansion_direction")}>
				<label className={isNonRestricted(values.expansion_direction)? "adjusted_multiselect" : ""}>
					{intl.formatMessage({
						id: "LABEL_EXPANSION_DIRECTION",
						defaultMessage: "Expansion Direction",
					})}
				</label>
				<Select
					multiple={isNonRestricted(values.expansion_direction)}
					options={expansion_directions(intl)}
					name="expansion_direction"
					size="small"
					placeholder={intl.formatMessage({
						id: "HINT_EXPANSION_DIRECTION",
						defaultMessage: "Select Option(s)",
					})}
					value={values.expansion_direction}
					onChange={onChangeDirection}
					disabled={is_on_site}
				/>
				<div className="custom-error">{errors["expansion_direction"]}</div>
			</Form.Field>
		</>}
		<Form.Field inline required error={errors.hasOwnProperty("mraid_type")}>
			<label style={{"verticalAlign": "top"}}>
				{intl.formatMessage({
					id: "LABEL_DIMENSIONS",
					defaultMessage: "Dimensions",
				})}
			</label>
			<div style={{"display": "inline-block"}}>
				<div>
					<Radio
						name="dimension"
						label={intl.formatMessage({
							id: "DIMENSIONS_STANDARD",
							defaultMessage: "Standard",
						})}
						value="standard"
						checked={values.dimension === "standard"}
						onChange={onChange}
						disabled={is_on_site}
					/>
					<Radio
						style={{"marginLeft": "15px"}}
						name="dimension"
						label={intl.formatMessage({
							id: "DIMENSIONS_CUSTOM",
							defaultMessage: "Custom",
						})}
						value="custom"
						checked={values.dimension === "custom"}
						onChange={onChange}
						disabled={is_on_site}
					/>
				</div>
				<div style={{"marginTop": "15px"}}>
					{values.dimension === "standard" && <Select
						name="dimensions"
						options={getDimensionOptions(values)}
						value={values.dimensions}
						placeholder={intl.formatMessage({
							id: "HINT_STANDARD_DIMENSIONS",
							defaultMessage: "Select one",
						})}
						onChange={onChange}
						disabled={is_on_site}
					/>}
					{values.dimension === "custom" &&
					<>
						<Input
							name="dimension_width"
							defaultValue={values.dimension_width}
							onBlur={onChange}
							placeholder={intl.formatMessage({
								id: "LABEL_WIDTH",
								defaultMessage: "Width",
							})}
							type="number"
							min={1}
							max={99999}
							onChange={handleNumber}
							step={1}
							style={{"marginRight": "10px", "width": "100px"}}
							disabled={is_on_site}
						/>
						<Input
							name="dimension_height"
							defaultValue={values.dimension_height}
							onBlur={onChange}
							placeholder={intl.formatMessage({
								id: "LABEL_HEIGHT",
								defaultMessage: "Height",
							})}
							type="number"
							min={1}
							max={99999}
							onChange={handleNumber}
							step={1}
							style={{"width": "100px"}}
							disabled={is_on_site}
						/>
					</>
					}
				</div>
			</div>
			<div className="custom-error">{errors["mraid_type"]}</div>
		</Form.Field>
		<Form.Field inline required error={errors.hasOwnProperty("file_type")}>
			<label>
				{intl.formatMessage({
					id: "LABEL_FILE_TYPE",
					defaultMessage: "File Type",
				})}
			</label>
			<Select
				options={file_types(intl)}
				name="file_type"
				placeholder={intl.formatMessage({
					id: "HINT_FILE_TYPE",
					defaultMessage: "Select one",
				})}
				defaultValue={values.file_type.toLowerCase()}
				onChange={onChange}
				disabled={is_on_site}
			/>
			<div className="custom-error">{errors["file_type"]}</div>
		</Form.Field>
	</>;
});

export default CreativeDisplayForm;
