import {
  getApiDate,
  isApiDateTimeExpired,
  parseAPIDateTimeToLocalTZDateObj
} from "../../../../libs/common_utils";

import {
  Grid,
  Menu,
  Pagination,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";

import CreativesGridContext from "../context";
import FilterControl from "./filter_control";
import { FormattedCreativeStatus } from "./formatted_creative_status";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import uuid from "uuid/v4";
import { Config } from "../../../../config/api";

/**
 * Render creative approval grid
 * @param {array} items
 * @param {object} controls
 */

export const CreativesApprovalGrid = ({items, controls}) => {
  const intl = useIntl();
  const context = React.useContext(CreativesGridContext);
  const {whitelabelChannelDisplay, whitelabelChannelVideo, whitelabelDoubleCreativeApproval} = context.ld_flags;

  return (
    <>
      <Grid className="common_grid">
        <Grid.Row>
          <Grid.Column>
            <Menu pointing secondary>
              {whitelabelChannelDisplay && (
                <Menu.Item
                  as={NavLink}
                  active={false}
                  to="/creatives/display"
                  content={intl.formatMessage({
                    id: "LINK_DISPLAY",
                    defaultMessage: "Display",
                  })}
                />
              )}
              {whitelabelChannelVideo && (
                <Menu.Item
                  as={NavLink}
                  active={false}
                  to="/creatives/video"
                  content={intl.formatMessage({
                    id: "LINK_VIDEO",
                    defaultMessage: "Video",
                  })}
                />
              )}
              {whitelabelDoubleCreativeApproval && (
                <Menu.Item
                  as={NavLink}
                  active={true}
                  to="/creatives/approve"
                  content={intl.formatMessage({
                    id: "LINK_ON_SITE_CREATIVES",
                    defaultMessage: "On-Site Creatives",
                  })}
                />
              )}
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment basic style={{"padding": "0"}} loading={context.gridLoading}>
        <Grid className="common_grid">
          <Grid.Row>
            <Grid.Column>
              <FilterControl
                filter={controls.filter}
                onChange={context.filtering}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table className="custom-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {intl.formatMessage({
                        id: "LABEL_CREATIVE_NAME",
                        defaultMessage: "Creative Name",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {intl.formatMessage({
                        id: "LABEL_ADVERTISER",
                        defaultMessage: "Advertiser",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_CREATIVE_TYPE",
                        defaultMessage: "Creative Type",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_STATUS",
                        defaultMessage: "Status",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">
                      {intl.formatMessage({
                        id: "LABEL_END_DATE",
                        defaultMessage: "End Date",
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left" style={{"width": "30%"}}>
                      {intl.formatMessage({
                        id: "LABEL_REJECTION_REASON",
                        defaultMessage: "Rejection Reason",
                      })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map((item) => {
                    return <CreativeGridItem key={uuid()} {...item} />;
                  })}
                  {!items.length && (
                    <GridEmptyRow
                      filterIsEmpty={controls.filter.isEmpty()}
                    />
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan="6" textAlign="right">{controls.pager.total_pages > 1 && <Pagination
                      size="mini"
                      defaultActivePage={controls.pager.page}
                      totalPages={controls.pager.total_pages}
                      firstItem={null}
                      lastItem={null}
                      onPageChange={context.getPage}
                    />}
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
};
CreativesApprovalGrid.propTypes = {
  "items": PropTypes.array.isRequired,
  "controls": PropTypes.object.isRequired
};

const GridEmptyRow = () => {
  const intl = useIntl();

  return (
    <Table.Row>
      <Table.Cell colSpan="6" textAlign="center">
        {intl.formatMessage({
          id: "EMPTY_SEARCH_RESULTS",
          defaultMessage: "No results found",
        })}
      </Table.Cell>
    </Table.Row>
  );
};

/**
 * Generate grid item
 * @param {object} item
 * @return {JSX.Element}
 * @constructor
 */
const CreativeGridItem = item => {
  const intl = useIntl();

  const media_type_translation = (item.media_type === "display")? intl.formatMessage({
      id: "MEDIA_TYPE_DISPLAY",
      defaultMessage: "Display"
    }) : intl.formatMessage({
    id: "MEDIA_TYPE_VIDEO",
    defaultMessage: "Video"
  });

  /**
   * format creative end date
   * @param {string} end_date
   * @returns {string}
   */
  const get_end_date = end_date => {
    const endDateObjLocalTz = parseAPIDateTimeToLocalTZDateObj(end_date)
    let creativeEndDate = getApiDate(endDateObjLocalTz, Config.fullDateTimeFormat);

    if(isApiDateTimeExpired(endDateObjLocalTz)) {
      const expired_translation = intl.formatMessage({
        id: "TEXT_EXPIRED",
        defaultMessage: "expired"
      });
      creativeEndDate = `${creativeEndDate} (${expired_translation})`;
    }

    return creativeEndDate;
  };

  return (
    <>
      <Table.Row className={item.status ? "" : " inactive"}>
        <Table.Cell className="text-ellipsis">{item.title}</Table.Cell>
        <Table.Cell>{item.advertiser_name}</Table.Cell>
        <Table.Cell>{media_type_translation}</Table.Cell>
        <Table.Cell>
          <FormattedCreativeStatus intl={intl} status={item.status} />
        </Table.Cell>
        <Table.Cell className={isApiDateTimeExpired(parseAPIDateTimeToLocalTZDateObj(item.end_date))? "date_expired" : ""}>{get_end_date(item.end_date)}</Table.Cell>
        <Table.Cell className="text-ellipsis">
          <Popup
            content={item.rejected_reason || "-"}
            disabled={!item.rejected_reason}
            size="tiny"
            inverted
            position="top left"
            trigger={<span>{item.rejected_reason || "-"}</span>}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};
