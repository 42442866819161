import { omit } from "lodash";
import { formatDateToISO } from "../libs/common_utils.js";
import { removeEmptyKeys } from "../libs/common_utils.js";

export default class CreativeModel {
  constructor(
    id,
    status,
    title,
    advertiserId,
    companionAd,
    brandName,
    adTitle,
    logoUrl,
    buttonColor,
    buttonText,
    deepLink,
    clickthroughUrl,
    landingPageUrl,
    startDate,
    endDate,
  ) {
    this.id = Number.parseInt(id, 10) || 0;
    this.status = Number.parseInt(status, 10) || 0;
    this.title = title || "";
    this.advertiser_id = Number.parseInt(advertiserId, 10) || 0;
    this.companion_ad = Number.parseInt(companionAd, 10) || 0;
    this.brand_name = brandName || "";
    this.ad_title = adTitle || "";
    this.logo_url = logoUrl || "";
    this.button_color = buttonColor || "";
    this.button_text = buttonText || "";
    this.deep_link = deepLink || "";
    this.clickthrough_url = clickthroughUrl || "";
    this.landing_page_url = landingPageUrl || "";
    this.start_date = startDate ? formatDateToISO(startDate, true) : "";
    this.end_date = endDate ? formatDateToISO(endDate, true) : "";

    removeEmptyKeys(this);
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new CreativeModel(
      json.id,
      json.status,
      json.title,
      json.advertiser_id,
      json.companion_ad,
      json.brand_name,
      json.ad_title,
      json.logo_url,
      json.button_color,
      json.button_text,
      json.deep_link,
      json.clickthrough_url,
      json.landing_page_url,
      json.start_date,
      json.end_date,
    );
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
    };

    return json;
  }
}

class Creative {
  constructor(id, status, title, advertiserId) {
    this.id = Number.parseInt(id, 10) || 0;
    this.status = Number.parseInt(status, 10) || 0;
    this.title = title || "";
    this.advertiser_id = Number.parseInt(advertiserId, 10) || 0;
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new Creative(json.id, json.status, json.title, json.advertiser_id);
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
    };

    return json;
  }
}

export class CreativeVideo3PModel extends Creative {
  constructor(id, status, title, advertiserId, customVastUrl, externalIdentifier, landingPageUrl, startDate, endDate) {
    super(id, status, title, advertiserId);
    this.third_party = 1;
    this.custom_vast_url = customVastUrl || "";
    this.external_identifier = externalIdentifier || "";
    this.landing_page_url = landingPageUrl || "";
    this.start_date = startDate ? formatDateToISO(startDate, true) : "";
    this.end_date = endDate ? formatDateToISO(endDate, true) : "";

    removeEmptyKeys(this);
  }

  /**
   * transform data that comes from API/json
   * @param {object} json
   */
  static fromJson(json) {
    return new CreativeVideo3PModel(
      json.id,
      json.status,
      json.title,
      json.advertiser_id,
      json.custom_vast_url,
      json.external_identifier,
      json.landing_page_url,
      json.start_date,
      json.end_date,
    );
  }

  /**
   * convert model into json format
   * @return {object}
   */
  toJson() {
    const json = {
      ...this,
    };

    const keysToOmit = [];
    if (!json.id) {
      keysToOmit.push("id");
    }

    if (!json.external_identifier) {
      keysToOmit.push("external_identifier");
    }

    return omit(json, keysToOmit);
  }
}
